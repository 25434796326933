<template>
	<div class="wrapper">
		<div class="article">
			<el-skeleton :loading="loading" animated :count="5">
				<el-card class="box-card" shadow="never">
					<div slot="header" class="clearfix">
						<span>{{ article.title }}</span>
					</div>
					<div class="html-content" v-html="article.content"></div>
				</el-card>
			</el-skeleton>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import { Skeleton, SkeletonItem, Carousel, Card, CarouselItem, Empty, Image, Avatar } from 'element-ui';

Vue.use(Skeleton).use(SkeletonItem).use(Card).use(Carousel).use(CarouselItem).use(Empty).use(Image).use(Avatar)

import {getGoodsArticleItem} from '@/api/pc'

export default {
	components: {},
	data () {
		return {
			article: null,
			loading: true
		}
	},
	computed: {},
	created () {},
	mounted () {
		this._loadData()
	},
	methods: {
		_loadData() {
			getGoodsArticleItem({id: this.$route.query.id}).then(res => {
				this.loading = false
				if(res.errCode === 0) {
					this.article  = res.data
				}
			}).catch(err => {
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>
@import '~@/css/pc-article.css';

.article {
	margin-top: 10px;
}

.html-content /deep/ .MsoNormal {
	font-size: 18px;
	line-height: 30px;
}

.html-content /deep/ p img {
	max-width: 400px;
}

.html-content /deep/ .single-content-one {
	width: 400px;
	margin: 0 auto;
}

</style>
